import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import TinyDatePicker from 'tiny-date-picker';
import classnames from 'classnames';

import { universalNoTimestamp } from 'dpl/shared/utils/dateFormat';
import Icon from 'dpl/common/components/Icon';

const DATEPICKER_OPTIONS = {
  format: universalNoTimestamp,
  mode: 'dp-below',
  dateClass: () => '',
  parseFormat: str => {
    const date = new Date(str);
    return Number.isNaN(Number(date)) ? new Date() : date;
  }
};

export const DATEPICKER_ID = 'date-picker-input';

export default class DatePicker extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    name: PropTypes.string,
    inputId: PropTypes.string,
    className: PropTypes.string,
    dateFormatFunc: PropTypes.func,
    parseFormatFunc: PropTypes.func,
    disabled: PropTypes.bool,
    startDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.number
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.number
    ]),
    displayCalendarIcon: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    placeholder: null,
    displayCalendarIcon: false,
    date: null,
    name: 'date',
    className: '',
    dateFormatFunc: DATEPICKER_OPTIONS.format,
    parseFormatFunc: DATEPICKER_OPTIONS.parseFormat,
    disabled: false,
    startDate: -Infinity,
    endDate: Infinity,
    inputId: 'default',
    onOpen: null,
    onClose: null
  };

  ref = createRef();
  datePicker = null;

  areDatesEqual = (dateOne, dateTwo) => {
    const { dateFormatFunc } = this.props;
    return (
      dateFormatFunc(new Date(dateOne || null)) ===
      dateFormatFunc(new Date(dateTwo || null))
    );
  };

  componentDidMount() {
    const { date, dateFormatFunc, parseFormatFunc, startDate, endDate } =
      this.props;
    this.datePicker = TinyDatePicker(this.ref.current, {
      ...DATEPICKER_OPTIONS,
      format: dateFormatFunc,
      parse: parseFormatFunc,
      min: '01/01/1900',
      inRange: _date =>
        this.areDatesEqual(_date, date) ||
        (_date >= startDate && _date <= endDate)
    })
      .on('select', this.handleDatePickerChange)
      .on('open', this.handlePickerOpen)
      .on('close', this.handlePickerClose);

    this.updateDatePicker(date);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.areDatesEqual(nextProps.date, this.datePicker.state.selectedDate) &&
      !this.areDatesEqual(nextProps.date, this.props.date)
    ) {
      this.updateDatePicker(nextProps.date);
    }
  }

  componentWillUnmount() {
    this.datePicker.destroy();
  }

  updateDatePicker = propsDate => {
    // unbind change handler when it isn't triggered by user, otherwise
    // props.onChange is triggered when props.date changes
    this.datePicker.off('select', this.handleDatePickerChange);
    this.datePicker.setState({
      selectedDate: propsDate && new Date(propsDate)
    });
    this.datePicker.on('select', this.handleDatePickerChange);
  };

  handleDatePickerChange = (_, dp) => {
    if (!this.areDatesEqual(this.props.date, dp.state.selectedDate)) {
      this.props.onChange(dp.state.selectedDate);
    }
  };

  handlePickerOpen = () => {
    this.props.onOpen?.();
  };

  handlePickerClose = () => {
    this.props.onClose?.();
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      name,
      placeholder,
      className,
      disabled,
      displayCalendarIcon,
      inputId
    } = this.props;

    return (
      <div className="DatePicker relative pointer font-16">
        {displayCalendarIcon && (
          <Icon
            className="ml3 transform-center left-0 pointer-events-none tertiary"
            name="fetch-calendar"
          />
        )}
        <input
          id={`${DATEPICKER_ID}-${inputId}`}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          readOnly
          type="text"
          ref={this.ref}
          data-test-id={`${DATEPICKER_ID}-${inputId}`}
          data-quiz-autofocus={false}
          className={classnames(
            'pointer w-100 input-reset ba br3 b--light-gray pv3 bg-white',
            className,
            {
              'pl10 pr4': displayCalendarIcon,
              ph4: !displayCalendarIcon
            }
          )}
        />
        {!disabled && (
          <Icon
            className="mr1 transform-center right-0 pointer-events-none"
            name="fetch-chevron-down"
          />
        )}
      </div>
    );
  }
}
