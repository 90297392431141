export function universalNoTimestamp(date) {
  return date.toUTCString().replace(/\s\d+:\d+:\d+ GMT/, '');
}

/*
 *  Used to parse "getShortDateString" method in date.js
 */
export function onParseShortDate(date) {
  if (!date) {
    return new Date();
  }

  const splitDate = date.toString().trim().split(' ');
  const hasYear = splitDate.length === 3;
  const currentYear = new Date().getFullYear();
  const result = new Date(date);

  // This is done because tiny-date-picker needs the year when parsing.
  // Otherwise the year turns into 2001
  if (!hasYear) {
    result.setFullYear(currentYear);
  }

  return result;
}
