import { useState } from 'react';
import { useSelector } from 'react-redux';

import { noop } from 'dpl/shared/utils';
import UploadInstance, { UPLOAD_STATES } from 'dpl/shared/utils/UploadInstance';

const { UPLOADING, SUCCESS, ERROR } = UPLOAD_STATES;

function getUploadsByState(uploads, state) {
  return uploads.filter(({ status }) => status === state);
}

export default function useUploadCapabilities(
  onUploadSuccess = noop,
  onUploadError = noop
) {
  const directUploadUrl = useSelector(
    ({ server: { directUploadURL } }) => directUploadURL
  );

  const [allUploads, setAllUploads] = useState([]);

  // function called with a FileList (likely e.target.files in most cases)
  function uploadFiles(files) {
    const newUploads = [...files].map(file => {
      const upload = new UploadInstance({ file, url: directUploadUrl });
      upload.start();
      return upload;
    });

    setAllUploads([...allUploads, ...newUploads]);

    Promise.all(newUploads.map(({ promise }) => promise))
      .then(() => onUploadSuccess(newUploads))
      .catch(err => onUploadError(err, getUploadsByState(newUploads, ERROR)));
  }

  function clearAllUploads() {
    setAllUploads([]);
  }

  return {
    uploadFiles,
    clearAllUploads,
    allUploads,
    pendingUploads: getUploadsByState(allUploads, UPLOADING),
    successfulUploads: getUploadsByState(allUploads, SUCCESS),
    errorUploads: getUploadsByState(allUploads, ERROR)
  };
}
