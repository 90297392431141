import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function LoadingWrapper({
  isLoading,
  children,
  className,
  fallback
}) {
  if (isLoading) {
    return (
      <div
        data-test-id="loading-wrapper"
        className={classnames(className, {
          'w-100 loading-spinner': !fallback
        })}
      >
        {fallback}
      </div>
    );
  }

  if (typeof children === 'function') {
    return children();
  }

  return children;
}

LoadingWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  fallback: PropTypes.node
};

LoadingWrapper.defaultProps = {
  isLoading: false,
  className: null,
  fallback: null
};
