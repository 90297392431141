export const VIEWPORT_TYPES = {
  CIRCLE: 'circle',
  SQUARE: 'square'
};

export const UP = 1;
export const LEFT = 8;
export const DOWN = 3;
export const RIGHT = 6;
export const ORIENTATIONS = [UP, LEFT, DOWN, RIGHT];
export const ORIENTATION_TO_DEGREES = {
  [UP]: 0,
  [LEFT]: -90,
  [DOWN]: -180,
  [RIGHT]: -270
};

export const HELPER_TEXT = 'Drag to reposition the photo';
export const HELPER_STYLES = {
  'font-size': '48px',
  'font-weight': 200,
  'line-height': '32px',
  color: '#040416',
  height: '32px',
  position: 'absolute',
  width: '32px'
};

export const HELPER_GUTTER = 40;

export const VIEWPORT_WIDTH_MOBILE = 220;
export const VIEWPORT_WIDTH = 300;

export const ICON_WIDTH_MOBILE = '32px';
export const ICON_WIDTH = '24px';
