import { useState, useRef, useEffect } from 'react';

export default function useDraggingOver(element = document) {
  const draggingRef = useRef();
  const endRef = useRef();
  const [isDragging, setDragging] = useState(false);
  draggingRef.current = isDragging;

  useEffect(() => {
    if (!element) return undefined;

    function dragStart() {
      window.cancelAnimationFrame(endRef.current);
      if (!draggingRef.current) {
        setDragging(true);
      }
    }
    function dragEnd() {
      // `requestAnimationFrame` frame is helpful for debouncing `dragleave` as well as giving
      // an element time to fire `onChange` before unmounting.
      endRef.current = window.requestAnimationFrame(() => {
        setDragging(false);
      });
    }

    element.addEventListener('dragover', dragStart, { passive: true });
    element.addEventListener('drop', dragEnd, { passive: true });
    element.addEventListener('dragleave', dragEnd, { passive: true });

    return () => {
      element.removeEventListener('dragover', dragStart);
      element.removeEventListener('drop', dragEnd);
      element.removeEventListener('dragleave', dragEnd);
    };
  }, [element]);

  return isDragging;
}
