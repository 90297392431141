import * as ActiveStorage from 'activestorage/src/index';

import { READABLE_IMAGE_MIME_TYPES } from 'dpl/shared/constants/mimeTypes';

export const UPLOAD_STATES = {
  IDLE: 'idle',
  UPLOADING: 'uploading',
  SUCCESS: 'success',
  ERROR: 'error'
};

const { IDLE, UPLOADING, SUCCESS, ERROR } = UPLOAD_STATES;

export default class UploadInstance {
  directUpload = null;
  promise = null;

  status = IDLE;

  file = null;
  name = '';

  progress = 0;
  total = 0;

  error = null;
  blob = {};

  onProgress = null;

  constructor({ file, url }) {
    this.file = file;
    this.name = file.name;
    this.directUpload = new ActiveStorage.DirectUpload(file, url, this);
    this.tempId = `${file.name}_${new Date().getTime()}`;

    if (file?.type?.includes('video/')) {
      this.dataUrl = URL.createObjectURL(file);
    } else if (READABLE_IMAGE_MIME_TYPES.includes(file.type)) {
      this.reader = new window.FileReader();
      this.reader.addEventListener('load', () => {
        this.dataUrl = this.reader.result;
      });
      this.reader.readAsDataURL(file);
    }
  }

  start() {
    this.promise = new Promise((res, rej) => {
      this.status = UPLOADING;

      this.directUpload.create((error, blob) => {
        if (error) {
          this.error = error;
          this.status = ERROR;

          window.bugsnagClient?.notify(error);

          return rej(error, this);
        }

        this.blob = blob;
        this.status = SUCCESS;

        return res(this);
      });
    });

    return this.promise;
  }

  directUploadWillStoreFileWithXHR(req) {
    req.upload.addEventListener('progress', event =>
      this.directUploadDidProgress(event)
    );
  }

  directUploadDidProgress(event) {
    const { total, loaded } = event;
    this.total = total;
    this.progress = loaded / total;

    if (this.onProgress) {
      this.onProgress(this.progress);
    }
  }
}
