// WIP

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../components/Icon';
import {
  BADGE_VARIANTS,
  BADGE_SIZE_CLASSES,
  COLOR_OPTIONS
} from './utils/constants';

export default function Badge({
  children,
  className,
  removeButtonClassName,
  variant,
  color,
  size,
  onDelete
}) {
  const isDeletable = typeof onDelete === 'function';
  return (
    <div
      className={classnames(
        'DSBadge inline-flex items-center justify-between br-pill no-select',
        `DSBadge--${size}`,
        BADGE_VARIANTS[variant][color],
        BADGE_SIZE_CLASSES[size],
        { 'DSBadge--deletable': isDeletable },
        className
      )}
      data-test-id="DSBadge"
    >
      <span className="DSBadge__label">{children}</span>
      {isDeletable && (
        <>
          <span className="DSBadge__divider bl b--stone-700 o-10" />
          <button
            className={classnames(
              'inline-flex items-center justify-center br-100 pv1 ph1 mr1',
              removeButtonClassName
            )}
            type="button"
            aria-label="Remove"
            onClick={onDelete}
          >
            <Icon name="fetch-close" height="18px" width="18px" />
          </button>
        </>
      )}
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  removeButtonClassName: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(BADGE_VARIANTS)),
  color: PropTypes.oneOf(COLOR_OPTIONS),
  size: PropTypes.oneOf(Object.keys(BADGE_SIZE_CLASSES)),
  onDelete: PropTypes.func
};

Badge.defaultProps = {
  className: null,
  removeButtonClassName: null,
  variant: 'light',
  color: 'blue',
  size: 'default',
  onDelete: null
};
