// Generic ErrorBoundary that returns null when erred

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class NullEB extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = { hasError: false };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
