import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import withToastNotifications from 'dpl/decorators/withToastNotifications';
import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';

const FILE_MISMATCH_MESSAGE = 'File types are not supported';
const { ERROR } = TOAST_NOTIFICATIONS_TYPES;

function Upload({
  accept,
  children,
  className,
  disabled,
  multiple,
  name,
  onChange,
  pushToastNotification
}) {
  const [debounceClick, setDebounceClick] = useState(false);

  return (
    <div className={className}>
      {children}
      <input
        accept={accept}
        disabled={disabled}
        className={classnames(
          'w-100 h-100 o-0 overflow-hidden absolute left-0 top-0 f0',
          { pointer: !disabled }
        )}
        multiple={multiple}
        name={name}
        type="file"
        onClick={e => {
          if (debounceClick) {
            e.preventDefault();
          } else {
            setDebounceClick(true);
            setTimeout(() => setDebounceClick(false), 1000);
          }
        }}
        onChange={e => {
          e.preventDefault();

          // dumb hacky way to prevent dragging files with invalid specifiers
          if (accept && !accept.includes('*')) {
            const acceptedSpecifiers = accept.split(/\s*,\s*/);
            if (
              [...e.target.files].some(
                f => !acceptedSpecifiers.includes(f.type)
              )
            ) {
              pushToastNotification({
                type: ERROR,
                message: FILE_MISMATCH_MESSAGE
              });
              return;
            }
          }

          onChange(e);
        }}
      />
    </div>
  );
}

Upload.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pushToastNotification: PropTypes.func.isRequired
};

Upload.defaultProps = {
  accept: null,
  children: null,
  className: '',
  disabled: false,
  multiple: false
};

export default withToastNotifications(Upload);
