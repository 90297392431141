export const IMAGE_MIME_TYPES = [
  'image/heic',
  'image/heif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/tiff',
  'image/webp',
  'image/avif'
];

// all except heic and heif
// which don't return valid data urls when read by FileReader.readAsDataURL
export const READABLE_IMAGE_MIME_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/tiff',
  'image/webp',
  'image/avif'
];

export const IMAGE_OR_VIDEO_MIME_TYPES = [...IMAGE_MIME_TYPES, 'video/*'];

export const FILE_TYPES = [
  'application/pdf',
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'image/jpeg',
  'image/png',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // ppsx
  'application/vnd.ms-powerpoint', // ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.ms-excel', // xls
  'text/plain',
  'text/html',
  'image/gif'
];
