/**
 * This component is a big hack to create a clickable <div>.
 * <button> (or even <a>) should ALWAYS be preferred for click interactions,
 * so this is a last resort to support clickable elements with complex descendants.
 *
 * Before using this component, ask yourself:
 *   - Can I use a <button>?
 *   - Can I use an <a>?
 *   - Can the contents inside the clickable area be organized
 *     strictly with phrasing content (i.e., <span>s as opposed to <div>s)?
 *   - How will this affect accessibility?
 *
 * Use this sparingly!!!!!
 */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

export default function ClickableDiv({
  children,
  onClick,
  tabIndex,
  className,
  ...rest
}) {
  const handleClick = useCallback(
    e => {
      const { target } = e;
      target.setAttribute('aria-pressed', !target.getAttribute('aria-pressed'));
      onClick(e);
    },
    [onClick]
  );

  const handleKeyDown = useCallback(
    e => {
      if ([' ', 'Enter', 'Spacebar'].includes(e.key)) {
        handleClick(e);
      }
    },
    [handleClick]
  );

  return (
    <div
      aria-pressed={false}
      className={classname('ClickableDiv pointer', className)}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={tabIndex}
      {...rest}
    >
      {children}
    </div>
  );
}

ClickableDiv.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  className: PropTypes.string
};

ClickableDiv.defaultProps = {
  tabIndex: 0,
  className: null
};
