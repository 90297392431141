export const COLOR_OPTIONS = [
  'stroll',
  'rubber',
  'branch',
  'tennis',
  'gray',
  'blue',
  'sky'
];

// BADGE
export const BADGE_COLOR_OPTIONS = {
  stroll: 'stroll',
  rubber: 'rubber',
  branch: 'branch',
  tennis: 'tennis',
  gray: 'gray',
  blue: 'blue',
  sky: 'sky'
};

export const BADGE_VARIANT_OPTIONS = {
  light: 'light',
  dark: 'dark',
  bright: 'bright'
};

export const BADGE_LIGHT_COLORS = {
  stroll: 'bg-stroll-light stroll-dark',
  rubber: 'bg-rubber-light rubber-dark',
  branch: 'bg-branch-light branch-dark',
  tennis: 'bg-tennis-light tennis-dark',
  gray: 'bg-passive secondary',
  blue: 'bg-cloud midnight',
  sky: 'bg-sky midnight'
};

export const BADGE_DARK_COLORS = {
  stroll: 'bg-stroll stroll-light',
  rubber: 'bg-rubber-dark rubber-light',
  branch: 'bg-branch-dark branch-light',
  tennis: 'bg-tennis tennis-dark',
  gray: 'bg-default white',
  blue: 'bg-midnight cloud',
  sky: 'bg-royal-blue sky'
};

export const BADGE_BRIGHT_COLORS = {
  stroll: 'bg-stroll-dark stroll-light',
  rubber: 'bg-rubber rubber-light',
  branch: 'bg-branch branch-light',
  tennis: 'bg-tennis-dark tennis-light'
};

export const BADGE_VARIANTS = {
  [BADGE_VARIANT_OPTIONS.light]: BADGE_LIGHT_COLORS,
  [BADGE_VARIANT_OPTIONS.dark]: BADGE_DARK_COLORS,
  [BADGE_VARIANT_OPTIONS.bright]: BADGE_BRIGHT_COLORS
};

export const BADGE_SIZE_OPTIONS = {
  default: 'default',
  small: 'small'
};

export const BADGE_SIZE_CLASSES = {
  [BADGE_SIZE_OPTIONS.default]: 'font-14',
  [BADGE_SIZE_OPTIONS.small]: 'font-12'
};

// BANNER
export const BANNER_COLORS = {
  stroll: 'bg-stroll-light b--stroll-light',
  rubber: 'bg-rubber-light b--rubber-light',
  branch: 'bg-branch-light b--branch-light',
  tennis: 'bg-tennis-light b--tennis-light',
  gray: 'bg-subtle-light-gray b--default',
  blue: 'bg-cloud b--cloud',
  sky: 'bg-sky b--sky',
  white: 'bg-white b--default' // not part of design system
};

export const BANNER_ICON_VARIANTS = {
  light: {
    stroll: 'bg-stroll stroll-light',
    rubber: 'bg-rubber rubber-light',
    branch: 'bg-branch branch-light',
    tennis: 'bg-tennis tennis-dark',
    gray: 'bg-white ba b--default secondary',
    blue: 'bg-white ba b--default midnight',
    sky: 'bg-white ba b--default midnight'
  },
  dark: {
    stroll: 'bg-stroll-dark stroll-light',
    rubber: 'bg-rubber-dark rubber-light',
    branch: 'bg-branch-dark branch-light',
    tennis: 'bg-tennis-dark tennis-light',
    gray: 'bg-default white',
    blue: 'bg-midnight cloud',
    sky: 'bg-midnight sky',
    white: 'bg-subtle-gray stone-700' // not part of design system
  }
};

export const PILLS_VARIANT = {
  TEXT: 'text',
  PHOTO: 'photo',
  ICON: 'icon'
};
