import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'dpl/common/components/Icon';

export default function RadioButton({
  checked,
  id,
  name,
  onChange,
  title,
  value,
  newDesignSystemStyles
}) {
  return (
    <div className="RadioButton">
      <label htmlFor={id} className="flex items-center">
        <RadioButtonInput
          checked={checked}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          newDesignSystemStyles={newDesignSystemStyles}
        />
        <p
          className={classnames('pl1', {
            'font-16 secondary': newDesignSystemStyles
          })}
        >
          {title}
        </p>
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  newDesignSystemStyles: PropTypes.bool
};

RadioButton.defaultProps = {
  value: null,
  newDesignSystemStyles: false
};

export function RadioButtonInput({
  checked,
  id,
  name,
  onChange,
  value,
  newDesignSystemStyles
}) {
  const iconName = checked ? 'radio-on' : 'radio-off';

  return (
    <div className="RadioButtonInput dib" data-test-id="RadioButtonInput">
      <input
        className="RadioButton__input"
        checked={checked}
        id={id}
        name={name}
        onChange={() => {}}
        onClick={onChange}
        type="radio"
        value={value}
      />
      <Icon
        name={newDesignSystemStyles ? `fetch-${iconName}` : iconName}
        className={classnames({
          'blue-500': newDesignSystemStyles
        })}
      />
    </div>
  );
}

RadioButtonInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  newDesignSystemStyles: PropTypes.bool
};

RadioButtonInput.defaultProps = {
  value: null,
  newDesignSystemStyles: false
};
