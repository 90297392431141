import React from 'react';
import PropTypes from 'prop-types';

import useFormContext from 'dpl/shared/form/hooks/useFormContext';

import SimpleInput from './SimpleInput';

export default function Input({ onChange, ...inputProps }) {
  const {
    formFieldErrors,
    formFieldId,
    formFieldValue,
    handleFormFieldChange
  } = useFormContext(inputProps.name);

  return (
    <SimpleInput
      errors={formFieldErrors}
      id={formFieldId}
      value={formFieldValue}
      onChange={onChange || handleFormFieldChange}
      {...inputProps}
    />
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

Input.defaultProps = {
  onChange: null
};
